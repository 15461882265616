import type { NilRankingResponseRelatedNilRankingResponseRelatedPagedData } from '@on3/api';
import { SidebarWrapper } from 'components/UI/Layout/Containers/Sidebar/SidebarWrapper';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import styles from './Nil100WidgetItems.module.scss';
import { NilSportWidgetItem } from './NilSportWidgetItem';

export const NilSportWidget = ({
  list,
  relatedModel,
}: NilRankingResponseRelatedNilRankingResponseRelatedPagedData) => {
  return (
    <SidebarWrapper>
      <div className={styles.nil100Icon}>
        <Text className={styles.sportHeader} component="h5" variant="overline">
          {relatedModel?.organizationType} {relatedModel?.sport?.name} NIL
          Rankings
        </Text>
      </div>
      <div className={styles.subTitleContainer}>
        <Text className={styles.subTitle} variant="subtitle1">
          NIL Rank
        </Text>
        <Text className={styles.subTitle} variant="subtitle1">
          NIL Valuation
        </Text>
      </div>
      <ol className={styles.sideContainer}>
        {list?.map((item) => {
          return (
            item.person && (
              <NilSportWidgetItem {...item} key={item.person?.key} />
            )
          );
        })}
      </ol>
      <div className={styles.linkContainer}>
        <TextLink
          color="primary"
          href="/nil/rankings/player/high-school/football/"
          underline="none"
          variant="subtitle1"
        >
          Complete List
        </TextLink>
        <TextLink
          color="primary"
          href="/nil/news/about-on3-nil-valuation-per-post-value/"
          underline="none"
          variant="subtitle1"
        >
          About
        </TextLink>
      </div>
    </SidebarWrapper>
  );
};
