import type { NilRankingResponse } from '@on3/api';
import {
  formatCurrency,
  formatMillions,
} from '@on3/ui-lib/src/utils/formatters';
import { SportIcon } from 'components/Icons/SportIcon';
import { On3Avatar } from 'components/Image/On3Avatar';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import styles from './Nil100WidgetItems.module.scss';

export const NilSportWidgetItem = ({
  person,
  valuation,
}: NilRankingResponse) => {
  if (!person) {
    return null;
  }

  const { defaultAsset, slug, name, positionAbbreviation } = person;

  return (
    <li className={styles.itemContainer}>
      <Text className={styles.playerRank} variant="subtitle1">
        {valuation?.groupRank}
      </Text>

      <On3Avatar
        altText={defaultAsset?.title ?? 'player headshot'}
        className={styles.headShot}
        domain={defaultAsset?.domain}
        height={50}
        radiusFive
        source={defaultAsset?.source}
        title={defaultAsset?.title ?? ''}
        width={50}
      />
      <div className={styles.infoContainer}>
        {/* TODO: this is only football/basketball now, but come back and put check. We need default sport in person response */}
        <TextLink href={`/db/${slug}/nil/`} underline="none" variant="h5">
          {name}
        </TextLink>
        <div className={styles.positionContainer}>
          <SportIcon className={styles.sportIcon} sportName="Football" />

          <Text className={styles.positionAbbreviation} variant="caption">
            {positionAbbreviation}
          </Text>
        </div>
      </div>
      <div className={styles.valuationContainer}>
        <Text className={styles.valuation} variant="body1">
          {valuation?.valuation ? formatCurrency(valuation?.valuation) : '-'}
        </Text>
        <Text className={styles.followers} variant="caption">
          {valuation?.followers ? formatMillions(valuation?.followers) : '-'}
        </Text>
        <Text className={styles.followers} variant="caption">
          followers
        </Text>
      </div>
    </li>
  );
};
